<template>
  <q-page padding>
    <DeeplinkApiGenerator />
  </q-page>
</template>

<script>
import DeeplinkApiGenerator from '@/components/api/DeeplinkApiGenerator.vue';
export default {
  name: 'DeeplinkApi',
  components: { DeeplinkApiGenerator }
};
</script>

<style></style>
