<template>
  <q-card flat class="justify-center">
    <q-card-section>
      <q-toolbar-title class="text-5 q-mr-auto q-ma-sm">{{ $t('deeplink_api') }}</q-toolbar-title>
      <q-breadcrumbs class="q-mr-auto q-ma-sm">
        <q-breadcrumbs-el :label="$t('dashboard')" to="/ " />
        <q-breadcrumbs-el :label="$t('deeplink_api')" exact />
      </q-breadcrumbs>
    </q-card-section>
    <q-card-section>
      <q-banner class="bg-info text-white q-mb-md" rounded>
        <q-item>
          <q-item-section top avatar>
            <q-icon name="mdi-information-outline" size="lg" />
          </q-item-section>
          <q-item-section>
            <q-item-label caption lines="3" class="text-white">
              Clique
              <a
                href="https://drive.google.com/file/d/1y4L5MMj5c1cCwEDLN1mzmtpcGzU3X8Si/view"
                target="_blank"
                class="q-mt-md"
                style="text-decoration: none"
              >
                <strong>aqui</strong>
              </a>
              para saber mais.
            </q-item-label>
          </q-item-section>
        </q-item>
      </q-banner>
    </q-card-section>

    <q-separator spaced />
    <q-card-section class="row q-col-gutter-md">
      <div class="col-6">
        <q-input outlined readonly type="text" v-model="affiliateData.id" :label="$t('affiliate_id')">
          <template v-slot:append>
            <q-btn color="primary" no-caps unelevated icon="mdi-content-copy" @click="copyData(affiliateData.id)">
              <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px"> Copiar ID </q-tooltip>
            </q-btn>
          </template>
        </q-input>
      </div>

      <div class="col-6">
        <q-input outlined readonly type="text" v-model="affiliateData.token" label="Token">
          <template v-slot:append>
            <q-btn color="primary" no-caps unelevated icon="mdi-content-copy" @click="copyData(affiliateData.token)">
              <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px">
                Copiar Token
              </q-tooltip>
            </q-btn>
          </template>
        </q-input>
      </div>

      <div class="col-6">
        <select-request
          :v-model="channel"
          outlined
          :endpoint="`redirect?app=AFFILIATE&path=/api/get/affiliate/site_active/list/${getProfiles.affiliate_id}`"
          option-label="name"
          option-value="id"
          :label="$t('select_channel')"
          clearable
          @update="(value) => (channel = value === null ? null : value.id)"
        />
      </div>

      <div class="col-6">
        <q-input outlined readonly type="text" v-model="channel" :label="$t('channel_id')"> </q-input>
      </div>
      <div class="col-6">
        <q-select
          v-model="campaign"
          :disable="!channel"
          option-label="campaign_name"
          :label="$t('select_campaign')"
          :options="campaignOptions"
          @input="(value) => (campaign_id = value ? value.campaign_id : null)"
          outlined
          clearable
        ></q-select>
      </div>

      <div class="col-6">
        <q-input outlined readonly type="text" v-model="campaign_id" :label="$t('campaign_id')"> </q-input>
      </div>

      <div class="col-12">
        <q-input
          outlined
          type="text"
          v-model="url"
          @input="validateAndEncodeUrl"
          @clear="(value) => (encodedUrl = '')"
          label="URL"
          clearable
        />
      </div>

      <div class="col-12">
        <q-input outlined readonly type="text" :value="getApiLink" :label="$t('shortned_link_api_generator')">
          <template v-slot:append>
            <q-btn color="primary" no-caps unelevated icon="mdi-content-copy" @click="copyData(getApiLink)">
              <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px">
                Copiar API
              </q-tooltip>
            </q-btn>
          </template>
        </q-input>
      </div>

      <div class="col-12">
        <q-input
          outlined
          readonly
          type="text"
          :value="getApiLinkAutoRedirect"
          :label="$t('auto_redirect_api_generator')"
        >
          <template v-slot:append>
            <q-btn color="primary" no-caps unelevated icon="mdi-content-copy" @click="copyData(getApiLinkAutoRedirect)">
              <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px">
                Copiar API
              </q-tooltip>
            </q-btn>
          </template>
        </q-input>
      </div>
    </q-card-section>
  </q-card>
</template>

<script>
const dataLink = process.env.VUE_APP_DATA_API;

import affiliateService from '../../services/AffiliateService';
import AffiliateMixin from '../../mixins/AffiliateMixin';
import SelectRequest from '../../widgets/inputsGlobal/SelectRequest';
import AdvertiserService from '@/services/AdvertiserService';

export default {
  components: { SelectRequest },
  mixins: [affiliateService, AffiliateMixin, AdvertiserService],
  name: 'DeeplinkApiGenerator',
  data() {
    return {
      affiliateData: {
        email: null,
        id: null,
        token: null
      },
      campaignOptions: [],
      apiDataUrl: dataLink,
      campaign: null,
      campaign_id: null,
      channel: null,
      login: null,
      url: '',
      encodedUrl: ''
    };
  },
  watch: {
    channel() {
      this.setupCampaigns();
    }
  },
  computed: {
    getProfiles: {
      get() {
        return this.$store.state.Account.user;
      }
    },
    getApiLink: {
      get() {
        if (this.campaign && this.channel && this.encodedUrl) {
          return `${this.apiDataUrl}/deeplink?token=${this.affiliateData.token}&canalid=${this.channel}&affid=${this.affiliateData.id}&progID=${this.campaign_id}&url=${this.encodedUrl}`;
        }

        return null;
      }
    },
    getApiLinkAutoRedirect: {
      get() {
        if (this.campaign && this.channel && this.encodedUrl) {
          return `${this.apiDataUrl}/v3/deeplink?token=${this.affiliateData.token}&canalid=${this.channel}&affid=${this.affiliateData.id}&progID=${this.campaign_id}&url=${this.encodedUrl}`;
        }

        return null;
      }
    }
  },
  methods: {
    async validateAndEncodeUrl(value) {
      if (value == '') this.encodedUrl = '';
      try {
        var url = new URL(value);
        if (url.protocol == 'https:') {
          this.encodedUrl = encodeURIComponent(url.toString());
        }
        return;
      } catch (error) {
        return;
      }
    },

    async setupCampaigns() {
      try {
        this.onLoading(true);
        const { data } = await this.listCampaignsBySite(this.channel);
        this.campaignOptions = data;
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    async setupAffiliateData() {
      this.onLoading(true);
      try {
        const { data, status } = await this.getAffiliateData(this.getProfiles.affiliate_id);
        if (status === 200) {
          this.affiliateData = data;
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    }
  },
  created() {
    this.setupAffiliateData();
  }
};
</script>

<style></style>
